<template>
    <b-container fluid>
      <b-row>
        <b-col>
          <div class="mb-2">
            <h5>
              <router-link :to="{ name: 'Case', params: { id: item.id }}"><h5>{{item.name}}</h5></router-link>
            </h5>
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'CaseSimple',
  props: ['item', 'username'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  }
}
</script>
